import { useUserStore } from '~/stores/user'
import { ROUTES } from '~/lib/constants/routes'

export function useRedirects() {
  const router = useRouter()
  const localePath = useLocalePath()
  const userStore = useUserStore()

  const redirectIfAuthenticated = function () {
    if (userStore.isAuthenticated) {
      return router.push(localePath(ROUTES.ACCOUNT))
    }
  }

  return { redirectIfAuthenticated }
}
